import React from 'react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';
import Layout from '../components/layout';
import Content from '../components/content';
import DocTOC from '../components/DocTOC';
import { View } from '../components/common';
import GHSlugger from 'github-slugger';
import CodeBlock from '../components/CodeBlock';
import './doc-page.css';

let slugger = new GHSlugger();

function Header({ is: Component, children, ...props }) {
  let slug;

  // Make it work if the header is wrapped in a component (like <code>)
  if (children && typeof children !== 'string') {
    if (children.props && typeof children.props.children === 'string') {
      slug = slugger.slug(children.props.children);
    } else {
      return <Component {...props} />;
    }
  } else {
    slug = slugger.slug(children);
  }

  return (
    <Component id={slug} {...props}>
      <div className="anchor-wrapper">
        <a
          className="anchor"
          href={`#${slug}`}
          aria-label={`${children} permalink`}
        >
          <svg
            aria-hidden="true"
            focusable="false"
            height="16"
            version="1.1"
            viewBox="0 0 16 16"
            width="16"
          >
            <path
              fill-rule="evenodd"
              d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            />
          </svg>
        </a>
      </div>
      {children}
    </Component>
  );
}

function DocPage({ data, pageContext }) {
  let post = data.mdx;
  let { title, toc, sectionName, comingSoon } = pageContext;
  slugger.reset();

  return (
    <MDXProvider
      components={{
        h1: props => <Header is="h1" {...props} />,
        h2: props => <Header is="h2" {...props} />,
        h3: props => <Header is="h3" {...props} />,
        h4: props => <Header is="h4" {...props} />,
        code: CodeBlock,
        Header
      }}
    >
      <Layout>
        <Content style={{ marginTop: 25 }}>
          <View className="md:flex-row">
            <DocTOC toc={toc} />
            <View style={{ flex: 1 }} className="docs pt-6 block">
              <div className="mb-2 text-gray-500">{sectionName}</div>
              <div className="flex items-center mb-16">
                <h1 className="m-0">{title}</h1>
                {comingSoon && (
                  <div className="rounded bg-green-500 block text-white text-sm px-2 ml-4">
                    Coming soon
                  </div>
                )}
              </div>
              <MDXRenderer>{post.body}</MDXRenderer>
            </View>
          </View>
        </Content>
      </Layout>
    </MDXProvider>
  );
}

export const pageQuery = graphql`
  query($id: String) {
    mdx(id: { eq: $id }) {
      body
    }
  }
`;

export default DocPage;
